const intro = `
  ##### Seasoned Web Development and Digital Strategy Professional with over 15 years of experience leading teams and delivering innovative digital solutions across various industries, including e-commerce, fashion, real estate, and investment banking. Expert in modern web technologies and passionate about integrating code, design, and communication to enhance brand articulation and customer engagement.
`

const staggerStick0 = `
  ###   Bon Bebé
  ####  Director of eCommerce - July 2023 - October 2024
`

const staggerExplain0a = `
  ##### Bon Bebé designs, produces, and distributes baby clothes and accessories under multiple brand-names. As Director of eCommerce my role was establishing an online direct to consumer retail presence for Bon Bebé's organic cotton brand Rabbit + Bear.
`

const staggerExplain0b = `
  [![Rabbit + Bear](/logos/rnb.svg)](https://rabbitandbear.com)
`

const staggerExplain0c = `
  ###### Established an online direct-to-consumer retail presence for Bon Bebé's organic cotton brand, Rabbit + Bear.

  -	Developed brand identity evolving Rabbit + Bear from just a name and a logo to a market ready brand.
  -	Designed and coded Shopify storefront, email marketing flows, and campaigns; Designed posts and managed the Instagram account.
  -	Organized and produced photo shoots, including casting models, story-boarding shots, and leading post-production.
  -	Crafted product descriptions optimized for customer engagement, keyword ad spend, and organic search.
`


const staggerStick1 = `
  ###   Adjmi Apparel Group
  ####  Digital Director - July 2020 - May 2022
`

const staggerExplain1 = `
  ##### Adjmi Apparel Group, located in the Garment District of Manhattan, designs, produces and distributes apparel to all tiers of retail. As Digital Director my central role was improving direct to consumer and marketplace brand performance.
  [![Apana Yoga](/logos/apana-logo.png)](https://amazon.com/apana)
  ###### Refocused brand to fill the Yoga apparel sector in the Amazon catalog.
  ###### The products launched under my direction are greatly outperforming historical performance at Adjmi.
  ###### Ad spend is more efficient because of higher conversion rates per product listing view
  ###### Listings are establishing themselves in category ranks.
  - Organized & produced photo-shoots; casting models, story-boarding shots, lead post-production
  - Developed product descriptions balancing customer persuasion, keyword ad spend, and organic amazon search
  - Sharpened brand articulation and direction to lean into the Yoga space

  [![Champion Logo](/logos/champion-logo.png)](https://www.amazon.com/s?i=merchant-items&me=A2A67I36WRS557&rh=p_4%3AChampion&dc&marketplaceID=ATVPDKIKX0DER)

  ###### Helped manage Champion Kid's apparel listings on Amazon

  - Refreshed graphic and product description listing content
  - Creatively worked around limited photography and resources
  - Increased page conversion rates and increased rankings

  [![Copper Compression Logo](/logos/copper-logo.png)](https://coppercompression.com)

  ###### Along with continuing web operations for Copper, I worked to transition the brand to get placement with major retailers
  ###### Copper Compression is now in Walmart and Target in addition to other retailers

  - Rebuilt Gatsby website to work on Shopify Theme engine enabling growing team to contribute content updates to site
  - Created a Photoshop automation workflow to efficiently create ad variations
  - Completed Drew Brees endorsement project
  - Created 3D packaging visualization pipeline to enhance pitches to major retailers.


  #### Adjmi Corporate

  ###### Along with the major brand responsibilities I also contributed to other projects within Adjmi

  - Built main corporate site (using Gatsby + Prismic) - adjmi.com
  - Refreshed DTC channel for Adjmi in-house brand: layer8.com
  - Created brand presence sites for Apana Yoga (apanalife.com) & Unipro (uniprosports.com)
  - Created graphics production workflow to improve Amazon listings for various brands 
`

const staggerStick2 = `
  ### Ettio
  #### Principal - March 2017 - July 2020
`

const staggerExplain2a = `
  ##### While living in Brooklyn, New York I operated Ettio as a sole proprietorship, creating bespoke web deliverables for various clients.
  Delivered full stack solutions for customer facing clients. Worked with designers to create brand guidelines and then built the projects.
  Used variety of technologies, including Shopify, React, Gatsby, Prismic, Laravel.
  - GradeNYC: Prismic CMS + Gatsby Frontend
  - Cherry Picks: Wagtail CMS + React Frontend
  - Cadence: Prismic CMS + React Frontend
  - Ironhound: Laravel CMS + Vue Frontend

`

const staggerExplain2b = `
[![Cadence Health](/logos/2_cadence.png)](https://cadenceotc.com/)
[![The Cherry Picks](/logos/2_cherryPicks.png)](https://.com)
[![Grade New York](/logos/2_grade.png "a title")](https://gradenewyork.com)
[![DTE Studio](/logos/2_dte.png "a title")](https://dtestudio.com)
[![Ironhound](/logos/2_ironhound.png "a title")](https://ironhound.com)
[![Layer 8](/logos/2_layer8.png "a title")](https://layer8.com)
`

const staggerExplain2c = `
  Originally worked with CopperCompression.com to revamp their Shopify presence. The brand had just been acquired from an independent operator by Adjmi Apparel Group, they wanted to diversify the online sales as 98% of sales were through Amazon. When the Covid lock-down happened I pushed to continue operations and stepped up taking on almost every role. I approached it like my own startup.

  - Because of my efforts we were able to win the mask scramble.
  - Brought over a million dollars of revenue in on the website for 3 straight months.
  - Created the 4th best performing single email on Klaviyo's platform for all of 2020 (https://www.klaviyo.com/blog/top-email-campaigns-examples)
`

const staggerStick3 = `
  ###   Williams New York
  ####  Digital Director - May 2014 - May 2017
`

const staggerExplain3a = `
  ##### Located in the Soho neighborhood of New York City, Williams New York is a leading real estate branding & marketing firm. We worked for NYC's biggest developers and brokerages to create and implement brand identities for their properties.
`

const staggerExplain3b = `
  ![Alt text](/logos/3_alchemy.png)
  ![Alt text](/logos/3_extell.png)
  ![Alt text](/logos/3_steiner.png)
  ![Alt text](/logos/CIM.png)
  ![Alt text](/logos/douglas-elliman.png)
  ![Alt text](/logos/fortis.png)
  ![Alt text](/logos/four-seasons.png)
  ![Alt text](/logos/houlihan.png)
  ![Alt text](/logos/spruce.png)
`
const staggerExplain3c = `
  As Digital Director I worked with department heads and clients to create unique digital experiences online and in sales offices.
  On each project I worked with the account manager to schedule milestones that fit their project and my team's capacity. Coordinated with other departments (3d renders, video, photography, copywriting) to procure the various media needed for our web deliverables.

  Worked to maintain a workflow where each site could be designed and built to look completely bespoke, while still identifying and developing key common features (such as real estate availability). When designers were able to work directly with developers early on in process, the creative exploration of cutting edge front-end techniques led to beautiful innovative sites that stayed within technical feasibility and budgetary scope. 

  - Managed up to 8 concurrent active projects from conception through completion.
  - Hired and developed team as project workload increased
  - Led team of 3-6 developers and designers
  - Created in-house real estate focused backend CMS with api integration to NYC real estate databases (Laravel + Vue)
  - Represented web department at client meetings

  Sample Projects
  - [Seaport Residences](https://seaportresidencesnyc.com/)
  - [One 57](https://one57.com/)

`

const staggerStick4 = `
  ###   Ettio
  ####  Principal - January 2012 - May 2014
`

const staggerExplain4 = `
  #####  Created a company to provide my web development design and development services on a consulting basis.
  Designed and built full stack web deliverables using CodeIgniter or Laravel, jQuery, and Bootstrap.

  Continued working with real estate projects while branching into other sectors including investment banking and social organizations. Highlights included Pach.org, Tag Asia Partners, and 135 West 52nd Street.
`

const staggerStick5 = `
  ###   The 7th Art
  ####  Senior Web Developer - April 2009 - December 2011 
`

const staggerExplain5a = `
  ##### Located in Soho New York, The 7th Art was a brand and marketing agency focused on luxury real estate and related markets. Served as lead web developer as well as liaison between account managers and designers.

  - Worked with Account Managers and Designers to find opportunities for elevated web experiences
  - Built dozens of web projects including:
  - Creating ThePlaza.com
  - Creating AlignedNYC database driven real estate listings site
`

const staggerExplain5b = `
  ![Alt text](/logos/3_plaza.png "a title")
  ![Alt text](/logos/3_jbgSmith.png "a title")
  ![Alt text](/logos/3_lenfant.png "a title")
`

const staggerExplain5c = `
  Started role helping company transition from flash technology to html5 websites. Led team in creation of subsequent web projects. Used myriad deliverables as opportunities to advance my technical skills, becoming an expert in Html, CSS, JavaScript, and PHP. 
`

const codaText = `

  ### Technical skills
  - HTML
  - CSS
  - Tailwind
  - React
  - Laravel
  - Shopify
  - PHP
  - Javascript
  - Adobe Creative Suite

  ### Skills 
  - Team Building
  - Client Management
  - Project Scheduling
  - Budgeting & Management
  - Creative Direction
  - Production Supervision
  - Digital Concepts
  - Web Development
  - Digital Design 

  ### Education 
  Northeastern University, Boston, MA
  --
  Northfield Mount Hermon, Northfield, MA
`

const contactText = `
  ### Download
  ## Save my resume as a PDF
  ![PDF Icon](https://dummyimage.com/64x64 "PDF Icon")
`

const footerText = `
  # Benjamin Lundquist
  ## benjamin@ettio.com
  ## 551-388-6763
`

const skillsList = `
  - React
  - Gatsby
  - CSS
  - HTML
  - JavaScript
  - PHP
  - Node
  - Branding
  - Marketing
  - Design
  - 3D Rendering
  - Motion Graphics
  - Animation  
`

const toc = `
  - Profile
  - Experience
  - Skills
  - Contact
`

export { intro, staggerStick0, staggerExplain0a, staggerExplain0b, staggerExplain0c, staggerStick1, staggerExplain1, staggerStick2, staggerExplain2a, staggerExplain2b, staggerExplain2c, staggerStick3, staggerExplain3a, staggerExplain3b, staggerExplain3c, staggerStick4, staggerExplain4, staggerStick5, staggerExplain5a, staggerExplain5b, staggerExplain5c, codaText, footerText, skillsList, contactText, toc }
